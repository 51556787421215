import React from 'react'
import Navbar from './Navbar'

const LoggedIn = () => {
    return (
        <div>
            <Navbar />
            <h2 style={{ marginLeft: "20px", color: "green" }}>
                Main page for Logged-in users
            </h2>
        </div>
    )
}

export default LoggedIn