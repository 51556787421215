import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import MyAutoComplete from './MyAutoComplete';
import WordTable from './WordTable';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import Navbar from './Navbar';
import { useParams } from 'react-router-dom';
import { createStyles, makeStyles } from "@mui/styles";
import { Adsense } from '@ctrl/react-adsense';


export function renderMila(params) {
    return <Typography paragraph style={{ fontSize: 30, fontWeight: 'bold' }}> {params.value} </Typography>
}
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme({
    direction: 'rtl',
});

export default function Milim() {
    // const { word } = useParams();
    const { service, param1 } = useParams();

    const findCharuz = async (word) => {
        setWord(word)
        document.title = word?.mila ? "הטיות למילה " + word?.mila : param1? "הטיות למילה " + param1 : "הטיות למילה "
        let params = {}
        if (word || param1) {
            let label = word?.data?._source?.mila + "," + word?.data?._source?.ktiv_chaser + "," + word?.data?._source?.chelek_diber + "," + word?.data?._source?.poal?.binyan + "," + word?.data?._source?.base_word_menukedet
            let mila_arr = label.split(",")

            params = {
                "word": word,
                "param1": param1,
            }
        }
        let query = `${process.env.REACT_APP_API_URL}/elk/get-hatayot`
        return axios.post(query, params).then(res => {
            setRows(res.data)
            return res.data
        }).catch(function (error) {
            console.log(error);
        });
    };

    const [rows, setRows] = React.useState([]);

    const [word, setWord] = React.useState({});

    React.useEffect(() => {
        if (word) findCharuz(word);
    }, [word]);
   
    return (
        <>
            <Navbar />
            <Box style={{ paddingRight: "30px", paddingLeft: "30px", whiteSpace: "pre-line" }} dir='rtl'>
                <Typography style={{ fontSize: 28, fontWeight: 'bold' }} paragraph> הטיות למילה {word != null && word?.mila != "" ? param1 : param1} </Typography>
                <CacheProvider value={cacheRtl}>
                    <ThemeProvider theme={theme}>
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <MyAutoComplete findCharuz={findCharuz} service={service} param1={param1} />
                                </Grid>
                                <br></br>
                                <WordTable word={word} rows={rows} />

                            </Grid>
                        </div>

                    </ThemeProvider>
                </CacheProvider >
                <Adsense
                    client="ca-pub-0752417813282550"
                    slot="3318252380"
                />
                {/* </Main> */}
            </Box>
        </>
    )
}
