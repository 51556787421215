import React, { useState, useEffect } from "react";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Grid, TextField, Box, Typography } from "@material-ui/core";
import { useNavigate, useLocation } from 'react-router-dom';

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

export default function Appa(props) {

  const navigate = useNavigate();
  const location = useLocation();
  const [inputValue, setInputValue] = useState(props.param1);
  const [inputSearch, setInputSearch] = useState("");
  const [options, setOptions] = useState([]);


  const debounceOnChange = React.useCallback(
    debounce(value => {
      setInputSearch(value);
    }, 400),
    []
  );

  const [open, setOpen] = React.useState(false);
  const loading = open && options.length === 0;

  function handleChange(value) {
    setInputValue(value);
    debounceOnChange(value);
  }

  useEffect(() => {
    let active = true;

    (async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/elk/search?q=${inputValue}`).then(res => {
        const data = res.data.map(el => {
          return {
            key: el._id,
            id: el._id,
            label: el._source?.ktiv_chaser,
            mila: el._source?.mila,
            data: el,
          }
        })
        return data;
      })

      if (active) {
        setOptions(response);
      }
    })();
  }, [inputSearch]);

  return (
    <Autocomplete
      autoHighlight={true}
      options={options}
      getOptionLabel={option => option.label}
      getOptionSelected={(option, value) => { return option.label === value.label }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      autoComplete
      loading={loading}
      loadingText="חפש מילה"
      noOptionsText={'המילה לא נימצאה'}
      inputValue={inputValue}
      // includeInputInList
      onChange={(event, newValue) => {
        if (newValue && newValue.mila) {
           if (location.pathname.split("/")[1] == "milim" ||location.pathname.split("/")[1] == "charuzim" ){
            navigate('/' + location.pathname.split("/")[1] + '/' + newValue?.mila);
          // } else if (location.pathname.split("/")[1] == "charuzim"){
            // navigate('/charuzim/' + newValue?.mila);
          }
        }
        setInputValue(newValue?.mila);
        props.findCharuz(newValue)
      }}
      dir="rtl"
      renderInput={params => (
        <TextField
          dir="rtl"
          {...params}
          // InputProps={{ style: { fontSize: 24, fontWeight: 'bold' } }}
          InputProps={{ ...params.InputProps, style: { fontSize: `24 !important`, fontWeight: 'bold' } }}
          label="מילה"
          variant="outlined"
          onChange={event => handleChange(event.target.value)}

        />
      )}
      renderOption={option => {
        return <Grid style={{ fontSize: 24, fontWeight: 'bold' }} container justifyContent="flex-end">{option.mila}</Grid>
      }}
    />
  );
}
