import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { signInWithGoogle, auth, signOut } from "../services/firebase";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer,
  Toolbar,
  CssBaseline,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  Avatar,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { Link, useNavigate } from "react-router-dom";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function Navbar() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
            TULIDO
          </Typography>
          <Avatar
            style={{ marginRight: "10px" }}
            sx={{ width: 50, height: 50 }}
            alt="Travis Howard"
            src={user?.photoURL}
          />

          <Box
            sx={{
              display: {
                xs: "none",
                sm: "flex",
              },
              alignItems: "center",
            }}
          >
            {!user && (
              <Button
                variant="contained"
                color="success"
                onClick={() =>
                  signInWithGoogle().then(() => {
                    navigate("/charuzim");
                  })
                }
              >
                Signin
              </Button>
            )}
            {user && (
              <>
                <Button variant="contained" color="error" onClick={signOut}>
                  Signout
                </Button>
              </>
            )}
          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Main open={open}>
        <DrawerHeader />
      </Main>
      <Drawer
        dir="rtl"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          <h2>{user?.displayName}</h2>
        </DrawerHeader>
        <Divider />
        <List>
          <Box
            sx={{
              display: {
                xs: "flex",
                sm: "none",
              },
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
              marginBottom: "5px",
            }}
          >
            {!user && (
              <Button
                variant="contained"
                color="success"
                onClick={() =>
                  signInWithGoogle().then(() => {
                    navigate("/charuzim");
                  })
                }
              >
                Signin
              </Button>
            )}
            {user && (
              <>
                <Avatar
                  sx={{ width: 50, height: 50 }}
                  alt="Travis Howard"
                  src={user?.photoURL}
                />
                <Button variant="contained" color="error" onClick={signOut}>
                  Signout
                </Button>
              </>
            )}
          </Box>

          <ListItem disablePadding>
            <ListItemButton
              // disabled={user?.email == "amos.shahar@gmail.com" ? false : true}
              onClick={() => navigate("/charuzim")}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText style={{ display: "flex" }} primary="חרוזים" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              // disabled={user?.email == "amos.shahar@gmail.com" ? false : true}
              onClick={() => navigate("/milim")}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText style={{ display: "flex" }} primary="הטיות" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              // disabled={user?.email == "amos.shahar@gmail.com" ? false : true}
              onClick={() => navigate("/faq")}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText style={{ display: "flex" }} primary="שאלות ותשובות" />
            </ListItemButton>
          </ListItem>

          {user?.email === "amos.shahar@gmail.com" && (
            <ListItem disablePadding>
              <ListItemButton
                // disabled={user?.email == "amos.shahar@gmail.com" ? false : true}
                onClick={() => navigate("/nakdan")}
              >
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText style={{ display: "flex" }} primary="נקדן" />
              </ListItemButton>
            </ListItem>
          )}

          {user?.email === "amos.shahar@gmail.com" && (
            <ListItem disablePadding>
              <ListItemButton
                // disabled={user?.email == "amos.shahar@gmail.com" ? false : true}
                onClick={() => navigate("/add-connection")}
              >
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText
                  style={{ display: "flex" }}
                  primary="הוספת קשרים"
                />
              </ListItemButton>
            </ListItem>
          )}

          <ListItem disablePadding>
            <ListItemButton
              // disabled={user ? false : true}
              onClick={() => navigate("/balance")}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText style={{ display: "flex" }} primary="Donate 1$" />
            </ListItemButton>
          </ListItem>
          {/* <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => navigate("/logged-out")}>
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText style={{ display: "flex" }} primary="Main(logged-out)" />
                        </ListItemButton>
                    </ListItem> */}

          {/* <Link to="/public" style={{ textDecoration: "none", color: "unset", cursor: "pointer" }}>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <InboxIcon />
                                </ListItemIcon>
                                <ListItemText style={{ display: "flex" }} primary="Public" />
                            </ListItemButton>
                        </ListItem>
                    </Link> */}
        </List>
      </Drawer>
    </Box>
  );
}
