import * as React from 'react';
import { useRef } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MyAutoComplete from './MyAutoComplete';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import Navbar from './Navbar';
import { useParams } from 'react-router-dom';
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import { Adsense } from '@ctrl/react-adsense';
import { useAuth } from '../AuthProvider';


const defaultTheme = createTheme();
const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                border: `1px solid ${theme.palette.divider}`,
                position: "relative",
                overflow: "hidden",
                width: "100%",
                height: 26,
                borderRadius: 2
            },
            value: {
                position: "absolute",
                lineHeight: "24px",
                width: "100%",
                display: "flex",
                justifyContent: "center"
            },
            bar: {
                height: "100%",
                "&.low": {
                    backgroundColor: "#f44336"
                },
                "&.medium": {
                    backgroundColor: "#efbb5aa3"
                },
                "&.high": {
                    backgroundColor: "#088208a3"
                }
            }
        }),
    { defaultTheme }
);
// interface ProgressBarProps {
//     value: number;
// }

const ProgressBar = React.memo(function ProgressBar(props) {
    const { value } = props;
    const valueInPercent = value * 100;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div
                className={classes.value}
            >{`${valueInPercent.toLocaleString()} %`}</div>
            <div
                className={clsx(classes.bar, {
                    low: valueInPercent < 30,
                    medium: valueInPercent >= 30 && valueInPercent <= 70,
                    high: valueInPercent > 70
                })}
                style={{ maxWidth: `${valueInPercent}%` }}
            />
        </div>
    );
});
export function renderProgress(params) {
    return <ProgressBar value={Number(params.value)} />;
}
export function renderMila(params) {
    return <Typography paragraph style={{ fontSize: 30, fontWeight: 'bold' }}> {params.value} </Typography>
}
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme({
    direction: 'rtl',
});

export default function Charuzim() {
    // const { word } = useParams();
    const { service, param1 } = useParams();

    const { currentUser } = useAuth();
//    console.log(JSON.stringify(currentUser.email))

    const findCharuz = async (word) => {
        setWord(word)
        document.title = word?.mila ? "חרוז למילה " + word?.mila : param1? "חרוז למילה " + param1 : "חרוז למילה"
        let params = {}
        if (word || param1) {
            let label = word?.data?._source?.mila + "," + word?.data?._source?.ktiv_chaser + "," + word?.data?._source?.chelek_diber + "," + word?.data?._source?.poal?.binyan + "," + word?.data?._source?.charuz?.ot_tnuaa_1 + "," + word?.data?._source?.charuz?.tnuaa_1 + "," + word?.data?._source?.charuz?.itzur_1 + "," + word?.data?._source?.charuz?.ot_tnuaa_2 + "," + word?.data?._source?.charuz?.tnuaa_2 + "," + word?.data?._source?.charuz?.itzur_2
            let mila_arr = label.split(",")

            params = {
                "word": word,
                "param1": param1,
                "charuz": {
                    "itzur_1": mila_arr[6],
                    "ot_tnuaa_1": mila_arr[4],
                    "tnuaa_1": mila_arr[5],
                    "itzur_2": mila_arr[9],
                    "ot_tnuaa_2": mila_arr[7],
                    "tnuaa_2": mila_arr[8],
                    "is_poal": is_poal,
                    "is_toar": is_toar,
                    "is_shem_etzem": is_shem_etzem,
                    "is_hatayot": is_hatayot
                }
            }
        }
        let query = `${process.env.REACT_APP_API_URL}/elk/get-charuzim`
        // let query = `http://localhost:3001/elk/get-charuzim` 
        return axios.post(query, params).then(res => {
            setRows(res.data)
            return res.data
        }).catch(function (error) {
            console.log(error);
        });
    };

    const [rows, setRows] = React.useState([]);

    const [word, setWord] = React.useState({});
    const [is_shem_etzem, setShemEtzem] = React.useState(true);
    const [is_toar, setToar] = React.useState(true);
    const [is_poal, setPoal] = React.useState(true);
    const [is_hatayot, setHatayot] = React.useState(false);

    React.useEffect(() => {
        if (word) findCharuz(word);
    }, [word, is_poal, is_shem_etzem, is_toar, is_hatayot]);


    const updatePoal = () => {
        is_poal ? setPoal(false) : setPoal(true)
    };
    const updateShemEtzem = () => {
        is_shem_etzem ? setShemEtzem(false) : setShemEtzem(true)
    };
    const updateToar = () => {
        is_toar ? setToar(false) : setToar(true)
    };
    const updateHatayot = () => {
        is_hatayot ? setHatayot(false) : setHatayot(true)
    };

    const columns = [
        {
            field: 'mila', headerName: 'מילה', width: 200,
            renderCell: renderMila
        },
        {
            field: 'score', headerName: 'איכות', width: 130,
            renderCell: renderProgress
        }
    ];
    // const { currentUser } = useAuth(); 
    return (
        <>
            <Navbar />
            <Box style={{ paddingRight: "30px", paddingLeft: "30px", whiteSpace: "pre-line" }} dir='rtl'>
                <Typography style={{ fontSize: 28, fontWeight: 'bold' }} paragraph> חרוז למילה {word != null && word?.mila != "" ? param1 : param1} </Typography>
                <CacheProvider value={cacheRtl}>
                    <ThemeProvider theme={theme}>
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={9} sm={10} md={10} lg={10} xl={10}>
                                    <MyAutoComplete findCharuz={findCharuz} service={service} param1={param1} />
                                </Grid>
                                <Grid item xs={3} sm={2} md={2} lg={2} xl={2}>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                            <Typography>סנן</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <FormGroup dir='rtl'>
                                                <FormControlLabel control={<Checkbox defaultChecked onClick={() => updatePoal()} />} label="פועל" />
                                                <FormControlLabel control={<Checkbox defaultChecked onClick={() => updateShemEtzem()} />} label="שם עצם" />
                                                <FormControlLabel control={<Checkbox defaultChecked onClick={() => updateToar()} />} label="שם תואר" />
                                                <FormControlLabel control={<Checkbox onClick={() => updateHatayot()} />} label="הטיות" />
                                            </FormGroup>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <br></br>
                                <div style={{ height: 500, width: '100%' }} dir="rtl">
                                    <DataGrid
                                        rows={rows}
                                        columns={columns}
                                        pageSize={100}
                                        rowsPerPageOptions={[100]}
                                    />
                                </div>
                            </Grid>
                        </div>

                    </ThemeProvider>
                </CacheProvider >
                <Adsense
                    client="ca-pub-0752417813282550"
                    slot="3318252380"
                />
                <Typography paragraph> - </Typography>
                <Typography paragraph> - </Typography>
                <Typography style={{ fontSize: 24, fontWeight: 'bold' }} paragraph> איך בנוי חרוז? </Typography>
                <Typography paragraph> א. שתי ההברות האחרונות במילה </Typography>
                <Typography paragraph>   כל הברה בנויה מ: </Typography>
                <Typography paragraph>&nbsp;&nbsp;&nbsp; &nbsp;1. עיצור התחלתי </Typography>
                <Typography paragraph>&nbsp;&nbsp;&nbsp; &nbsp;2. תנועה  </Typography>
                <Typography paragraph>&nbsp;&nbsp;&nbsp; &nbsp;3. עיצור סופי </Typography>
                <Typography paragraph> ב. משקל המילה: מילעל או מלרע </Typography>
                <Typography paragraph>  </Typography>
                <Typography paragraph> חרוז יכול לכלול את כל התכונות או את חלקם. </Typography>
                <Typography paragraph> לתכונות שונות, משקל שונה באיכות החרוז. למשל התנועה של ההברה האחרונה היא התכונה החשובה ביותר. </Typography>
                <Typography paragraph>  </Typography>
                <Typography paragraph> דוגמאות:  </Typography>
                <Typography paragraph> המילים גינה ומנגינה הן חרוז עם כל התכונות. </Typography>
                <Typography paragraph> ניתוח המילה בלון לצורכי מציאת חרוז: </Typography>
                <Typography paragraph>&nbsp;&nbsp; הברה אחרונה: לון </Typography>
                <Typography paragraph>&nbsp;&nbsp; &nbsp;&nbsp;  עיצור התחלתי: ל </Typography>
                <Typography paragraph>&nbsp;&nbsp; &nbsp;&nbsp;  תנועה: O (או) </Typography>
                <Typography paragraph>&nbsp;&nbsp; &nbsp;&nbsp;  עיצור סופי: נ </Typography>
                <Typography paragraph>&nbsp;&nbsp;  הברה לפני אחרונה: ב </Typography>
                <Typography paragraph>&nbsp;&nbsp; &nbsp;&nbsp;  עיצור התחלתי: ב </Typography>
                <Typography paragraph>&nbsp;&nbsp; &nbsp;&nbsp;  תנועה: A </Typography>
                <Typography paragraph>&nbsp;&nbsp; &nbsp;&nbsp;  עיצור סופי: ללא </Typography>
                <Typography paragraph>  </Typography>
                <Typography paragraph> המילה בוקר במובן שחר, היא מלעילית ולכן תתחרז טוב יותר עם מילים כמו שוקר או רוקר. לעומתה בוקר במובן מגדל בקר הינה במילרע ולכן תתחרז עם מילים כמו דוקר או נוקר. </Typography>
                {/* </Main> */}
            </Box>
        </>
    )
}