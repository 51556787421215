import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa"; // Import FontAwesome icons
import Navbar from "./Navbar";
import { useAuth } from "../AuthProvider";


export default function PP() {
  const [balance, setBalance] = React.useState(null);

  const [amount] = React.useState("1.00"); // Example amount in USD
  const [message, setMessage] = React.useState("");
  const [messageType, setMessageType] = React.useState("info"); // Types: 'info', 'success', 'error', 'warning'

  const { currentUser } = useAuth();
  console.log(JSON.stringify(currentUser?.email));

  return (
    <>
      <Navbar />
      <Box
        style={{
          paddingRight: "30px",
          paddingLeft: "30px",
          whiteSpace: "pre-line",
        }}
        // dir="rtl"
      >
        <div id="article" dir="rtl" class="blog-post">
          <h1>
            <a href="https://heb.tulidu.com" target="_blank">
              השוואת אתרים למציאת חרוזים בעברית
            </a>
          </h1>
          <Typography variant="h6" component="p" sx={{ fontWeight: 'bold', fontSize: '20px' }}>
          <p>
            אם אתה מחפש{" "}
            <a href="https://heb.tulidu.com" target="_blank">
              מקום מקוון למציאת חרוזים בעברית
            </a>
            , ישנם מספר אתרים שאתה עשוי לשקול, כולל Yo-Yoo.co.il, brachot.net,
            greetier.com, חרוז.com, ו-
            <a href="https://heb.tulidu.com" target="_blank">
              heb.tulidu.com
            </a>
            . אבל, איזה מהם מעניק לך את כל החרוזים באופן המדוייק ביותר?
          </p>

          <p>
            האתר{" "}
            <a href="https://heb.tulidu.com" target="_blank">
              heb.tulidu.com
            </a>{" "}
            מצטיין במגוון אפשרויות, כולל תמיכה בפועל, שם, עצם, שם תואר, והטיות,
            והוא מספק גם סינון לפי חלקי דיבר. בנוסף, heb.tulidu.com הוא האתר
            היחיד שתומך בניקוד בעברית, שמאפשר לו לבחון במדויק את ההבדלים בין
            מילים שנראות דומה אך מבוטאות או משמעותן שונה.
          </p>

          <p>
            אחד היתרונות של{" "}
            <a href="https://heb.tulidu.com" target="_blank">
              heb.tulidu.com
            </a>{" "}
            הוא היכולת לעבוד עם דגש ופתח גנובה, שניים מהפרטים הדקדקניים שמשפרים
            את איכות החרוזים. כמו כן, האתר מספק ציון לאיכות החרוז, ובכך מאפשר
            למשתמשים להעריך את איכות החרוז לפני שימוש.
          </p>

          <p>
            לעומת זאת, אתרים האחרים כוללים פחות תכונות וגמישות בחיפושים. לדוגמה,
            Yo-Yoo.co.il מציע חרוזים למילים בלי תלות בהטיות או בניקוד, אך הוא לא
            מציע היכולות המתקדמות של{" "}
            <a href="https://heb.tulidu.com" target="_blank">
              heb.tulidu.com
            </a>
            .
          </p>

          <p>
            בסיכום, בין האתרים שנבדקו,{" "}
            <a href="https://heb.tulidu.com" target="_blank">
              heb.tulidu.com
            </a>{" "}
            הוא בלתי ספק המקום המובחר לחיפוש חרוזים בעברית. האתר מציע את הגמישות
            הרבה ביותר, תוך שהוא מספק תמיכה מתקדמת בדקדוק, דיוק של חרוזים,
            ומאפשר למשתמשים למצוא חרוזים איכותיים ומדוייקים.
          </p>
          </Typography>
          <table>
            <thead>
              <tr>
                <th>תבחין</th>
                <th>דוגמה</th>
                <th>
                  <a
                    href="http://heb.tulidu.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    heb.tulidu.com
                  </a>
                </th>
                <th>
                  <a
                    href="http://חרוז.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    חרוז.com
                  </a>
                </th>
                <th>
                  <a
                    href="http://greetier.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    greetier.com
                  </a>
                </th>
                <th>
                  <a
                    href="http://brachot.net"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    brachot.net
                  </a>
                </th>
                <th>
                  <a
                    href="https://www.yo-yoo.co.il/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    yo-yoo.co.il
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>סינון לפי חלק דיבר</td>
                <td>פועל, שם, עצם, שם תואר, הטיות</td>
                <td>
                  <FaCheckCircle style={{ color: "green" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
              </tr>
              <tr>
                <td>תמיכה בניקוד</td>
                <td>סָפַר, סֵפֶר, סִפֵּר</td>
                <td>
                  <FaCheckCircle style={{ color: "green" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
              </tr>
              <tr>
                <td>אבחנה בדגש (בגד כפת)</td>
                <td>עפר פרפר (פ גדושה)</td>
                <td>
                  <FaCheckCircle style={{ color: "green" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
              </tr>
              <tr>
                <td>פתח גנובה</td>
                <td>אח מלוח, מתוח</td>
                <td>
                  <FaCheckCircle style={{ color: "green" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
              </tr>
              <tr>
                <td>ציון מדוייק לאכות החרוז</td>
                <td>95%</td>
                <td>
                  <FaCheckCircle style={{ color: "green" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <td>חלקי</td>
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
              </tr>
              <tr>
                <td>חרוז בן ט ת</td>
                <td>מיטה / חביתה</td>
                <td>
                  <FaCheckCircle style={{ color: "green" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <FaCheckCircle style={{ color: "green" }} />
                </td>
              </tr>
              <tr>
                <td>חרוז בן א ע</td>
                <td>נשיאה ו סיעה</td>
                <td>
                  <FaCheckCircle style={{ color: "green" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <td>מילה חסרה</td>
                </td>
              </tr>
              <tr>
                <td>חרוז בן כ ק</td>
                <td>סוכה חוקה</td>
                <td>
                  <FaCheckCircle style={{ color: "green" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
                <td>
                  <FaTimesCircle style={{ color: "red" }} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Box>
    </>
  );
}
