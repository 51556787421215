import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MyAutoComplete from './MyAutoComplete';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import Navbar from './Navbar';
import { useParams } from 'react-router-dom';
import { createStyles, makeStyles } from "@mui/styles";
import { Adsense } from '@ctrl/react-adsense';


export function renderMila(params) {
    return <Typography paragraph style={{ fontSize: 30, fontWeight: 'bold' }}> {params.value} </Typography>
}
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme({
    direction: 'rtl',
});

export default function Nakdan() {
    // const { word } = useParams();
    const { service, param1 } = useParams();

    const analyzeSentence = async () => {
        setWord(word)
        document.title = "נקדן"
        let params = {}

        params = {
            "sentences": message,
            "show_not_valid": show_not_valid
        }

        let query = `${process.env.REACT_APP_API_URL}/elk/analyze-sentence`
        return axios.post(query, params).then(res => {
            let itemList = [];
            res.data[0].sentence_options.forEach((option, index1) => {
                if ( !show_not_valid || option.is_valid ) {
                    itemList.push(<li key={index1}> option: {index1} {JSON.stringify(option)} </li>)
                    option.options.forEach((item, index2) => {
                        try {
                            itemList.push(
                                <Tooltip title={JSON.stringify(res.data[0].words[index2].options[item - 1])}>
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <div>
                                                <Typography display="inline" variant="body2" style={{ fontSize: 28, fontWeight: 'bold', color: 'blue' }}>
                                                    {res.data[0].words[index2].options[item - 1].prefix}{res.data[0].words[index2].options[item - 1].mila[0]}
                                                </Typography>
                                                <Typography display="inline" variant="body3" style={{ fontSize: 14, fontWeight: 'bold', color: 'blue' }}>
                                                    {res.data[0].words[index2].options[item - 1]._id}
                                                </Typography>
                                                <Typography display="inline" variant="body3" style={{ fontSize: 14, fontWeight: 'bold', color: 'red' }}>
                                                    {res.data[0].words[index2].options[item - 1].is_valid_word}
                                                    {res.data[0].words[index2].options[item - 1].not_valid_reason}
                                                </Typography>
                                            </div>
                                        }

                                    >
                                        {/* props={ style: { fontSize: 24, fontWeight: 'bold' } } */}
                                    </ListItemText>
                                </Tooltip>
                            )
                        } catch (err) {
                            // debugger
                        }
                    })
                }
            })
            setUnique_sentences(res.data[0].unique_sentences)
            setWord(itemList)
            return res.data
        }).catch(function (error) {
            console.log(error);
        });
    };

    const [word, setWord] = React.useState({});
    const [show_not_valid, setShow_not_valid] = React.useState(true);
    const [unique_sentences, setUnique_sentences] = React.useState({});
    const [message, setMessage] = React.useState('הספר גזר את השיער');

    React.useEffect(() => {
        if (word) analyzeSentence(word);
    }, [message]);

    const handleChange = (event) => {
        setMessage(event.target.value);
    };
    const updateShowNotValid = () => {
        show_not_valid ? setShow_not_valid(false) : setShow_not_valid(true)
    };
    return (
        <>
            <Navbar />
            <Box style={{ paddingRight: "30px", paddingLeft: "30px", whiteSpace: "pre-line" }} dir='rtl'>
                <Typography style={{ fontSize: 28, fontWeight: 'bold' }} paragraph> נקדן</Typography>
                <CacheProvider value={cacheRtl}>
                    <ThemeProvider theme={theme}>
                        <div>
                            <TextField onChange={handleChange} inputProps={{ style: { fontSize: 40 } }} fullWidth id="outlined-basic" label="הספר גזר את השיער" variant="outlined" />
                            <Button onClick={e => {
                                analyzeSentence()
                            }} variant="contained">נקד
                            </Button>
                            <FormGroup dir='rtl'>
                                <FormControlLabel control={<Checkbox defaultChecked onClick={() => updateShowNotValid()} />} label="רק תקינים" />
                            </FormGroup>
                        </div>
                        {/* <div> */}
                        {/* {unique_sentences} */}
                        {/* </div> */}
                        <div>
                            {word.length > 0 ? word : ""}
                        </div>

                    </ThemeProvider>
                </CacheProvider >
                <Adsense
                    client="ca-pub-0752417813282550"
                    slot="3318252380"
                />
                {/* </Main> */}
            </Box>
        </>
    )
}
