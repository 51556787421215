
      
   
import React, { useEffect } from "react";
import { useAuth } from "../AuthProvider";
  
  // const async function callServerToCapturePayment(orderID) => {
  const callServerToCapturePayment = async (orderID, currentUser) => {
    
    if (!currentUser) {
      // showMessage("You must be logged in to save settings.", "error"); // Modified line
      return;
    }

  try {
    const authToken = await currentUser.getIdToken();
    

    const apiUrl = `${process.env.REACT_APP_API_URL}/paypal/capture-payment`;
 
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ "orderId": orderID }),
    };

    const response = await fetch(apiUrl, options);
    const data = await response.json();
    console.log("Success:", data);
    // showMessage("Key saved successfully!", "success");
  } catch (error) {
    console.error("Error:", error);
    // showMessage("Failed to save the key.", "error");
  }
};

const PayPalButton = ({ amount, onSuccess, onError, onCancel }) => {
  const { currentUser } = useAuth();
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      // "https://www.paypal.com/sdk/js?client-id=ATmYX8rtp-UIH9yswPspuo617PvyvSNrSojX0Ut4OADFh1gLAR4L-PwQj00JvDITBYHlHFho7gEa0DJQ"; //sandbbox
         "https://www.paypal.com/sdk/js?client-id=AYSJw_4mOjvodcmzh5bYBBo1aOFiarc_LuihWpbhdwXV6LgayGOgx4jBC8l84-NHLrBmk4j-WJm1UuVL";
    script.addEventListener("load", () => {
      // @ts-ignore
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: amount, // Can dynamically set the amount
                  },
                },
              ],
            });
          },
          onApprove: (data, actions) => {
            // Instead of capturing the payment here, we send the order ID to the server
            return callServerToCapturePayment(data.orderID, currentUser).then((details) => {
              // TODO: Handle server response here, assuming your server sends back the payment details or a success message
              onSuccess(details);
            });
          },
          onCancel: () => {
            // Handle cancellation
            onCancel();
          },
          onError: (err) => {
            // Handle errors
            onError(err);
          },
        })
        .render("#paypal-button-container");
    });
    document.body.appendChild(script);
  }, [amount, onSuccess, onError, onCancel]);

  return <div id="paypal-button-container"></div>;
};

export default PayPalButton;
