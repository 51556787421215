import { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Public from './components/Public';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./services/firebase";
import { AuthProvider } from "./AuthProvider";
import Charuzim from './components/Charuzim';
import Milim from './components/Milim';
import Nakdan from './components/Nakdan';
import Balance from './components/Balance';
import CompareSites from './components/CompareSites';
import Settings from './components/Settings';
import AddConnection from './components/AddConnection';

import './App.css';
import LoggedIn from './components/LoggedIn';
import LoggedOut from './components/LoggedOut';
let loading = true
function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);



  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      setUser(user);
      setLoading(false)
    })
  }, [])

  console.log(" User " + user?.email);

  return (
    <AuthProvider>
      <div className="app">
        <BrowserRouter>
          <Routes>


            <Route exact path="/" element={<Navigate replace to="/charuzim" />} />

            <Route exact path="/logged-out" element={<LoggedOut />} />

            <Route exact path="/logged-in" element={user ? <LoggedIn /> : <Navigate replace to="/logged-out" />} />

            {/* <Route exact path='/charuzim' element={loading ? "Loading...." : user ? <Charuzim /> : <Navigate replace to="/oggled-in" />} />
            <Route exact path='/charuzim/:param1' element={loading ? "Loading...." : user ? <Charuzim /> : <Navigate replace to="/logged-in" />} /> */}

            {/* <Route exact path='/charuzim' element={loading ? "Loading...." : <Charuzim />} /> */}
            {/* <Route exact path='/balance/' element={loading ? "Loading...." : user ? <Balance /> : <Navigate replace to="/logged-in" />} />
            <Route exact path='/settings/' element={loading ? "Loading...." : user? <Settings /> : <Navigate replace to="/logged-in" />} />
            <Route exact path='/charuzim' element={loading ? "Loading...." : user?.email == "amos.shahar@gmail.com" ? <Charuzim /> : <Navigate replace to="/logged-in" />} />
            <Route exact path='/charuzim/:param1' element={loading ? "Loading....": user?.email == "amos.shahar@gmail.com" ? <Charuzim /> : <Navigate replace to="/logged-in" />} />
            <Route exact path='/milim' element={loading ? "Loading...." : user?.email == "amos.shahar@gmail.com" ? <Milim /> : <Navigate replace to="/logged-in" />} />
            <Route exact path='/milim/:param1' element={loading ? "Loading...." : user?.email == "amos.shahar@gmail.com" ? <Milim /> : <Navigate replace to="/logged-in" />} />
            <Route exact path='/nakdan/' element={loading ? "Loading...." : user?.email == "amos.shahar@gmail.com" ? <Nakdan /> : <Navigate replace to="/logged-in" />} />
            <Route exact path='/add-connection/' element={loading ? "Loading...." : user?.email == "amos.shahar@gmail.com" ? <AddConnection /> : <Navigate replace to="/logged-in" />} /> */}

            <Route exact path='/balance/' element={loading ? "Loading...." :  <Balance />} />
            <Route exact path='/charuzim' element={loading ? "Loading....": <Charuzim  />}  />
            <Route exact path='/charuzim/:param1' element={loading ? "Loading....": <Charuzim />} />
            <Route exact path='/milim' element={loading ? "Loading...." : <Milim /> } />
            <Route exact path='/milim/:param1' element={loading ? "Loading...." : <Milim />} />
            <Route exact path='/faq' element={loading ? "Loading...." : <CompareSites /> } />
            <Route exact path='/nakdan/' element={loading ? "Loading...." : user?.email == "amos.shahar@gmail.com" ? <Nakdan /> : <Navigate replace to="/logged-in" />} />
            <Route exact path='/add-connection/' element={loading ? "Loading...." : user?.email == "amos.shahar@gmail.com" ? <AddConnection /> : <Navigate replace to="/logged-in" />} />

            {/* <Route exact path='/charuzim/:word' element={user ? <Charuzim /> : <Navigate replace to="/logged-in" />} /> */}

            <Route exact path='/public' element={<Public />} />
          </Routes>
        </BrowserRouter>
      </div>
    </AuthProvider>
  );
}

export default App;