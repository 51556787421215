import React from 'react';

import { auth } from '../services/firebase'

import '../App.css';
import Navbar from './Navbar';

const Home = ({ user }) => {
  return (
    <div className="home">
      <Navbar />
      <h1>Public page, <span></span>{user?.displayName}</h1>
      <img src={user?.photoURL} alt="" />
      <button className="button signout" onClick={() => auth.signOut()}>Sign out</button>
    </div>
  )
}

export default Home;