import * as React from 'react';
import Typography from '@mui/material/Typography';
import Text from '@mui/material/TextField';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import createCache from '@emotion/cache';

export function renderMila(params) {
    return <Typography paragraph style={{ fontSize: 30, fontWeight: 'bold' }}> {params.value} </Typography>
}
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme({
    direction: 'rtl',
});

export default function WordTable(props) {
    const [rows, setRows] = React.useState(props.rows);
    // React.useEffect(() => {
    //     if (word) findCharuz(word);
    // }, [word]);

    let columns = []
    if (props?.rows[0]?.chelek_diber == "פועל") {
        columns = [
            {
                field: 'mila', headerName: 'מילה', width: 200,
                renderCell: renderMila
            },
            {
                field: 'poal_binyan', headerName: 'בניין', width: 200,
                renderCell: renderMila
            },
            {
                field: 'poal_zman', headerName: 'זמן', width: 200,
                renderCell: renderMila
            },
            {
                field: 'poal_guf', headerName: 'גוף', width: 200,
                renderCell: renderMila
            },
            {
                field: 'poal_mispar_dikduki', headerName: 'מספר דקדוקי', width: 200,
                renderCell: renderMila
            },
            {
                field: 'poal_min', headerName: 'מין', width: 200,
                renderCell: renderMila
            }

        ];
    } else if (props?.rows[0]?.chelek_diber == "שם עצם") {
        columns = [
            {
                field: 'mila', headerName: 'מילה', width: 200,
                renderCell: renderMila
            },
            {
                field: 'mispar_dikduki', headerName: 'מספר דקדוקי', width: 200,
                renderCell: renderMila
            },
            {
                field: 'shayachut_guf', headerName: 'גוף', width: 200,
                renderCell: renderMila
            },
            {
                field: 'shayachut_mispar_dikduki', headerName: 'ממספר דקדוקי', width: 200,
                renderCell: renderMila
            },
            {
                field: 'shayachut_min', headerName: 'מין', width: 200,
                renderCell: renderMila
            }

        ];
    } else if (props?.rows[0]?.chelek_diber == "שם תואר") {
        columns = [
            {
                field: 'mila', headerName: 'מילה', width: 200,
                renderCell: renderMila
            },
            {
                field: 'mispar_dikduki', headerName: 'מספר דקדוקי', width: 200,
                renderCell: renderMila
            },
            {
                field: 'guf', headerName: 'גוף', width: 200,
                renderCell: renderMila
            }, {
                field: 'min', headerName: 'מין', width: 200,
                renderCell: renderMila
            }

        ];
    }


    return (
        <>
            <Typography style={{ fontSize: 18 }} paragraph> חלק דיבר  : {props.rows[0]?.chelek_diber} </Typography>
            {/* <Typography style={{ fontSize: 24 }} paragraph> חלק דיבר: {props.rows[0]?.shoresh}</Typography> */}
            <div style={{ height: 1000, width: '100%' }} dir="rtl">
                <DataGrid
                    rows={props.rows}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                // components={{ Toolbar: GridToolbar, }}
                />
            </div>
        </>
    )
}