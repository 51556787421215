import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import axios from "axios";
import Navbar from "./Navbar";
import PayPalButton from "./PayPalButton";
import { useAuth } from "../AuthProvider";
import { db } from "../services/firebase";


export default function PP() {
  const [balance, setBalance] = React.useState(null);

  const [amount] = React.useState("1.00"); // Example amount in USD
  const [message, setMessage] = React.useState("");
  const [messageType, setMessageType] = React.useState("info"); // Types: 'info', 'success', 'error', 'warning'

  const displayMessage = (text, type = "info") => {
    setMessage(text);
    setMessageType(type);
  };

  const handleSuccess = (paymentDetails) => {
    displayMessage("Payment Successful", "success");
    // Additional success handling logic here
  };

  const handleError = (error) => {
    displayMessage("Payment Error: " + error.message, "error");
    // Additional error handling logic here
  };

  const handleCancel = () => {
    displayMessage("Payment Cancelled", "warning");
    // Additional cancellation handling logic here
  };

  const { currentUser } = useAuth();
  console.log(JSON.stringify(currentUser?.email));


  React.useEffect(() => {
    let unsubscribe = () => {}; // Initialize an empty unsubscribe function

    const listenToBalance = () => {
      if (currentUser) {
        const userEmail = currentUser.email;
        const docRef = db.collection("users").doc(userEmail);

        // Listen for real-time updates
        unsubscribe = docRef.onSnapshot(
          (doc) => {
            if (doc.exists) {
              setBalance(doc.data().balance);
            } else {
              console.log("No such document!");
            }
          },
          (error) => {
            console.log("Error listening to document:", error);
          }
        );
      }
    };

    listenToBalance();

    // Cleanup subscription on component unmount
    return () => unsubscribe();
  }, []);

  return (
    <>
      <Navbar />
      <Box
        style={{
          paddingRight: "30px",
          paddingLeft: "30px",
          whiteSpace: "pre-line",
        }}
        // dir="rtl"
      >

        <Typography style={{ fontSize: 28, fontWeight: "bold" }} paragraph>
           נהנת מהאתר? תרומה קטנה תעזור לשמור אותו למעלה 
        </Typography>
        <PayPalButton
          amount={amount}
          onSuccess={handleSuccess}
          onError={handleError}
          onCancel={handleCancel}
        />
        {message && (
          <div
            style={{
              marginTop: "20px",
              padding: "10px",
              backgroundColor:
                messageType === "error"
                  ? "#f8d7da"
                  : messageType === "success"
                  ? "#d4edda"
                  : messageType === "warning"
                  ? "#fff3cd"
                  : "#d1ecf1",
              color:
                messageType === "error"
                  ? "#721c24"
                  : messageType === "success"
                  ? "#155724"
                  : messageType === "warning"
                  ? "#856404"
                  : "#0c5460",
              borderRadius: "4px",
            }}
          >
            {message}
          </div>
        )}
      </Box>
    </>
  );
}
