import * as React from 'react';
import { useRef } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BaseWordAutoComplete from './BaseWordAutoComplete';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import Navbar from './Navbar';
import { useParams } from 'react-router-dom';
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import { Adsense } from '@ctrl/react-adsense';
import Button from '@mui/material/Button';


const defaultTheme = createTheme();
const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                border: `1px solid ${theme.palette.divider}`,
                position: "relative",
                overflow: "hidden",
                width: "100%",
                height: 26,
                borderRadius: 2
            },
            value: {
                position: "absolute",
                lineHeight: "24px",
                width: "100%",
                display: "flex",
                justifyContent: "center"
            },
            bar: {
                height: "100%",
                "&.low": {
                    backgroundColor: "#f44336"
                },
                "&.medium": {
                    backgroundColor: "#efbb5aa3"
                },
                "&.high": {
                    backgroundColor: "#088208a3"
                }
            }
        }),
    { defaultTheme }
);
// interface ProgressBarProps {
//     value: number;
// }

const ProgressBar = React.memo(function ProgressBar(props) {

    const { value } = props;
    const valueInPercent = value * 100;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div
                className={classes.value}
            >{`${valueInPercent.toLocaleString()} %`}</div>
            <div
                className={clsx(classes.bar, {
                    low: valueInPercent < 30,
                    medium: valueInPercent >= 30 && valueInPercent <= 70,
                    high: valueInPercent > 70
                })}
                style={{ maxWidth: `${valueInPercent}%` }}
            />
        </div>
    );
});
export function renderProgress(params) {
    return <ProgressBar value={Number(params.value)} />;
}
export function renderMila(params) {
    return <Typography paragraph style={{ fontSize: 30, fontWeight: 'bold' }}> {params.value} </Typography>
}
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme({
    direction: 'rtl',
});

export default function AddConnection() {
    // const { word } = useParams();
    const { service, param1 } = useParams();
    const updateConnection = (event) => {
        setConnection(event.target.value);
    };

    const updateWord1 = async (word) => {
        setWord1(word)
    };
    const updateWord2 = async (word) => {
        setWord2(word)
    };

    const [severity, setSeverity] = React.useState("success");
    const [message, setMessage] = React.useState("saved");
    const [word1, setWord1] = React.useState({});
    const [word2, setWord2] = React.useState({});
    const [connection, setConnection] = React.useState('');
    const [showConnectionsRes, setShowConnectionRes] = React.useState();
    const [open, setOpen] = React.useState(false);

    const addConnection = async () => {

        let params = {}

        params = {
            "word1": word1,
            "connection": connection,
            "word2": word2,
            "updated_by": ""

        }
      
        let query =`${process.env.REACT_APP_API_URL}/elk/save-words-connection`

        return axios.post(query, params).then(res => {
            if (res.status == 200) {
                setSeverity("success")
                setMessage("saved!")
            } else {
                setSeverity("error")
                setMessage("error " + res.statusText)
            }
            setOpen(true);
        }).catch((error) => {
            if (error.response) {
                console.log(error.response.data); // => the response payload 
            }
            setSeverity("error")
            setMessage("error")
            setOpen(true);
        })

    };
    const showConnections = async () => {

        let params = {}

        params = {
            "word1": word1,
            "connection": connection,
            "word2": word2,
            "updated_by": ""

        }

        let query = `${process.env.REACT_APP_API_URL}/elk/get-words-connections`

        return axios.post(query, params).then(res => {
            let itemList = [];
            res.data.forEach((connection, index) => {
                itemList.push(<li key={index}> {index+1}: {connection._source.word1_id} * {connection._source.connection} * {connection._source.word2_id}  </li>)
            })
            setShowConnectionRes(itemList)

        }).catch((error) => {
            if (error.response) {
                console.log(error.response.data); // => the response payload 
            }

        })

    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    // React.useEffect(() => {
    //     if (word) addConnection(word);
    // }, [word1, word2, connection]);

    return (
        <>
            <Navbar />
            <Box style={{ paddingRight: "30px", paddingLeft: "30px", whiteSpace: "pre-line" }} dir='rtl'>
                <Typography style={{ fontSize: 28, fontWeight: 'bold' }} paragraph>  הוספת קשרים  </Typography>
                <CacheProvider value={cacheRtl}>
                    <ThemeProvider theme={theme}>
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={9} sm={10} md={10} lg={10} xl={10}>
                                    <BaseWordAutoComplete updateWord={updateWord1} service={service} param1={param1} />
                                </Grid>
                                <Grid item xs={9} sm={10} md={10} lg={10} xl={10}>
                                    <Box >
                                        <InputLabel dir="rtl" id="demo-simple-select-label">חיבור</InputLabel>
                                        <Select
                                            fullWidth
                                            dir="rtl"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={connection}
                                            label="Age"
                                            onChange={updateConnection}
                                        >
                                            <MenuItem dir="rtl" value="פעולה על שם עצם">ֿפעולה על שם עצם</MenuItem>
                                            <MenuItem dir="rtl" value="מילה נרדפת">מילה נרדפת</MenuItem>
                                            <MenuItem dir="rtl" value="ההיפך">ההיפך</MenuItem>
                                            <MenuItem dir="rtl" value="תכונה של">תכונה של</MenuItem>
                                            <MenuItem dir="rtl" value="איבר בקבוצת">איבר בקבוצת</MenuItem>
                                            <MenuItem dir="rtl" value="חלק של">ֿחלק של</MenuItem>
                                        </Select>
                                    </Box>
                                </Grid>
                                <Grid item xs={9} sm={10} md={10} lg={10} xl={10}>
                                    <BaseWordAutoComplete updateWord={updateWord2} service={service} param1={param1} />
                                </Grid>
                                <br></br>
                            </Grid>
                            <Button onClick={e => {
                                addConnection()
                            }} variant="contained">הוסף
                            </Button>

                            <Button onClick={e => {
                                showConnections()
                            }} variant="contained"
                                sx={{ padding: 1, margin: 2 }}
                            >הצג קשרים קיימים
                            </Button>
                            <p>{showConnectionsRes}</p>
                            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                                    {message}
                                </Alert>
                            </Snackbar>
                        </div>
                    </ThemeProvider>
                </CacheProvider >
                <Adsense
                    client="ca-pub-0752417813282550"
                    slot="3318252380"
                />
                {/* </Main> */}
            </Box>
        </>
    )
}