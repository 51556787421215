import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyC4beq_ctqpVvKjUK2RLQwFtxv-pNVUmVc",
  authDomain: "tulidu-prod.firebaseapp.com",
  projectId: "tulidu-prod",
  storageBucket: "tulidu-prod.appspot.com",
  messagingSenderId: "36562032704",
  appId: "1:36562032704:web:efc437429cb609336a67a5"
};

// Initialize Firebase 
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => auth.signInWithPopup(provider);
export const signOut = () => auth.signOut(provider);
// export const firestore = firebase.firestore();
export const db = firebase.firestore();
export default firebase;