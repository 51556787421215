import React from 'react'
import Navbar from './Navbar'

const LoggedOut = () => {
    return (
        <div>
            <Navbar />
            <h2 style={{ marginLeft: "20px", color: "red" }}>
                Welcome to Tulidu, please login
            </h2>
        </div>
    )
}

export default LoggedOut